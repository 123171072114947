<template>
  <div class="calculator-container" tabindex="0">
    <div id="calculator" :class="{ loading: isLoading }">
      <div class="cromessage" v-if="!isLoading && (content.cromessage && content.cromessage != '')"
        v-html="content.cromessage" />
      <div class="spinner" v-if="isLoading">
        <Spinner name="circle" color="var(--color-links)" />
      </div>

      <div v-else>
        <calculator-select v-if="COMMERCE_IS_SELLING" />

        <div class="inner">
          <h5 class="calc-title" v-if="!COMMERCE_IS_SELLING">
            Buy Travel Money
          </h5>
          <div class="column">
            <div class="label row">
              <span>{{
                content.currencySelectLabel || '1. Select your currency'
              }}</span>
              <Tooltip :content="content.currencyTooltip" />
            </div>
            <CurrencySelect v-if="selectedCurrency && allCurrencies.length" :currencies="allCurrencies"
              :currency="selectedCurrency" @selected="selectCurrency($event)" />
          </div>

          <SellTransactionAmountPicker v-if="quote && isSellTransaction" />
          <BuyTransactionAmountPicker v-if="quote && isBuyTransaction" />

          <exchange-rate />

          <product-select v-if="isSellTransaction" />
          <product-selection />

          <store-finder v-if="showStoreFinder" />
          <modal-store v-if="showStoreModal" />

          <calculator-result v-if="showAddToCartButton"/>
          <ReturnToCart v-if="hasCart"/>

          <div v-if="optionalContent">
            <div class="optional-content" v-html="optionalContent" />
          </div>

        </div>
      </div>
    </div>
    <div v-html="content.trustPilotScript" v-if="content && content.trustPilotScript && isPageStore"
      class="trustpilot-banner" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  COMMERCE_IS_SELLING,
} from '@vue/common/constants/config'
import { ProductTypes } from '@vue/common/constants/productTypes'
import Spinner from 'vue-spinkit'
import Tooltip from '@vue/common/components/Tooltip'
import CurrencySelect from '@vue/common/components/form/CurrencySelect'
import CalculatorSelect from './components/calculator-select'
import BuyTransactionAmountPicker from './components/buy-transaction-amount-picker'
import SellTransactionAmountPicker from './components/sell-transaction-amount-picker'
import ExchangeRate from './components/exchange-rate'
import StoreFinder from './components/store-finder'
import ProductSelect from './components/product-select'
import ProductSelection from './components/product-selection'
import CalculatorResult from './components/calculator-result'
import ReturnToCart from './components/return-to-cart.vue'
import ModalStore from './components/modal-store'

export default {
  name: 'Calculator',
  components: {
    Spinner,
    Tooltip,
    CalculatorSelect,
    CurrencySelect,
    BuyTransactionAmountPicker,
    SellTransactionAmountPicker,
    ExchangeRate,
    StoreFinder,
    ProductSelect,
    ProductSelection,
    CalculatorResult,
    ReturnToCart,
    ModalStore,
  },
  data() {
    return {
      COMMERCE_IS_SELLING,
      isPageStore: true,
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'calculator/isLoading',
      isBuyTransaction: 'calculator/isBuyTransaction',
      isSellTransaction: 'calculator/isSellTransaction',
      hasCart: 'cart/hasCart'
    }),
    ...mapState({
      allCurrencies: (state) => state.currency.currencies,
      showStoreModal: (state) => state.calculator.showStoreModal,
      selectedCurrency: (state) => state.calculator.selectedCurrency,
      content: (state) => state.calculator.content,
      selectedProduct: (state) => state.calculator.selectedProduct,
      selectedStore: (state) => state.store.store,
      quote: (state) => state.calculator.quote,
    }),
    optionalContent() {
      const isBuyFromCustomerPerspective = this.isSellTransaction;

       // NOTE: optionalBuyContent is named from customer perspective
       // NOTE: optionalSellContent is named from customer perspective
      return isBuyFromCustomerPerspective
        ? this.content.optionalBuyContent
        : this.content.optionalSellContent;
    },
    showStoreFinder() {
      if (this.selectedProduct?.id === ProductTypes.HOME_DELIVERY) {
        return false;
      }
      return !this.hasCart;
    },
    showAddToCartButton() {
      if(this.hasCart) {
        return false; // Can't add any more to cart from this component.
      }

      if (this.selectedProduct?.id === ProductTypes.HOME_DELIVERY) {
        return true;
      }

      if(!this.selectedProduct || !this.selectedStore) {
        return false;
      }

      // Can only add for sellback/collection if a physical branch has been selected.
      return !this.selectedStore.isMeta;
    }
  },
  methods: {
    ...mapActions({
      initCalculator: 'calculator/init',
      selectCurrency: 'calculator/selectCurrency',
      getCalculatorContent: 'calculator/getCalculatorContent',
      getMatrix: 'calculator/getMatrix',
      setTransactionType: 'calculator/setTransactionType',
      setQuantity: 'calculator/setQuantity',
    }),
  },
  async mounted() {
    await this.initCalculator();
    this.getMatrix()
    this.getCalculatorContent()
    if (window.doc_type === "pageStore" || window.doc_type === "pageOurStoresRegion" || window.doc_type === "pageOurStores") {
      this.isPageStore = false;
    }
  },
}
</script>
