const joinNonEmptyStrings = (parts, delimiter) => parts.filter(el => !!el).join(delimiter);

const getAddressComponentsOfType = (place, type) => {
    let component = place.address_components.filter(x => x.types.includes(type));
    if (!component) {
        return "";
    }

    return joinNonEmptyStrings(component.map(c => c.long_name), ', ');
};

export const mapGooglePlaceToCommerceAddress = (place) => {
    let mappedAddress = {};

    if (!place.address_components) {
        return;
    }

    const premiseDetails = joinNonEmptyStrings([getAddressComponentsOfType(place, 'subpremise'), 
                                                getAddressComponentsOfType(place, 'premise')], 
                                                ', ');

    const streetNumber = getAddressComponentsOfType(place, 'street_number');
    const route = getAddressComponentsOfType(place, 'route');

    const localityDetails = joinNonEmptyStrings([getAddressComponentsOfType(place, 'sublocality'),
                                                 getAddressComponentsOfType(place, 'locality')],
                                                 ', ');

    if(premiseDetails) {
        mappedAddress.houseNameNumber = premiseDetails;
        mappedAddress.addressLine1 = joinNonEmptyStrings([streetNumber, route], ' ');
    }
    else {
        mappedAddress.houseNameNumber = streetNumber;
        mappedAddress.addressLine1 = route;
    }

    mappedAddress.addressLine2 = localityDetails;
    mappedAddress.townCity = getAddressComponentsOfType(place, 'postal_town');
    mappedAddress.postcodeZip = getAddressComponentsOfType(place, 'postal_code');

    return mappedAddress;
}
