import { getCurrencies } from '@vue/services/api/currency.api'

const state = {
  currencies: []
}

const mutationTypes = {
  SET_CURRENCIES: 'SET_CURRENCIES'
};

const actions = {
  getCurrencies({ commit, dispatch }) {
    getCurrencies().then(({ data: currencyData }) => {
      commit(mutationTypes.SET_CURRENCIES, currencyData)
      let queryCurrency = window.calculatorSettings.currency;

      // TODO: What's that for (WTF)?
      let bc = localStorage.getItem("selcurr");
      if (bc !== null) {
        queryCurrency = bc;
        localStorage.removeItem("selcurr");
      }

      commit("calculator/SET_CURRENCY",
        currencyData.find(x => x.code.toUpperCase() === queryCurrency.toUpperCase()),
        { root: true } 
      );
    })
  }
}

const mutations = {
  [mutationTypes.SET_CURRENCIES](state, currencies) {
    state.currencies = currencies
  }
}

const currency = {
  namespaced: true,
  state,
  actions,
  mutations
}

export default currency
