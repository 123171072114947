import { getProducts, getSiteProducts } from '@vue/services/api/product.api'
import { SET_PRODUCTS, SET_SITEPRODUCTS } from '@vue/common/constants/mutation.types'
import { actionTypes } from '@vue/common/constants/store.types'

const state = {
  products: [],
  siteProducts: []
}

const getters = {
  findProduct: (state) => (productId) => {
     return state.products.find((p) => p.id === productId) || null
  },
};

const actions = {
  async loadProducts({ commit }) {
    const response = await getProducts()
    const products = response.data.filter((product) => product.cartsEnabled)
    commit(SET_PRODUCTS, products)
  },
  [actionTypes.GetSiteProducts]({ commit, rootState }) {
    getSiteProducts().then(({ data }) => {
      commit(SET_SITEPRODUCTS,data)      
    })
  }
};

const mutations = {
  [SET_PRODUCTS](state, products) {
    state.products = products
  },
  [SET_SITEPRODUCTS](state, siteProducts) {
    state.siteProducts = siteProducts
  },
};

const product = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default product;
