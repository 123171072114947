import { getRate } from '@vue/services/api/currency.api';
import { getContent, getMatrix } from '@vue/services/api/utility.api'
import { AvailabilityMatrix } from '@vue/common/availabilityMatrix'
import { showError, showWarning } from '@vue/services/toast.service'

import {
  SET_CALCULATOR_CONTENT,
  SET_RESULT,
  SET_STORE_SELECT,
  SET_STORE_MODAL
} from '@vue/common/constants/mutation.types'

import BigNumber from 'bignumber.js'

import { actionTypes } from '@vue/common/constants/store.types'
import { nextMultiple } from '@vue/services/currency.service'
import { TransactionTypes } from '@vue/common/constants/transactionTypes'
import { ProductTypes } from '@vue/common/constants/productTypes'

const state = {
  content: null,
  showStoreModal: false,
  matrix: null,
  transactionType: null,
  quantity: null,
  selectedProduct: null,
  selectedCurrency: null,
  quote: null
}

const mutationTypes = {
  SET_TRANSACTION_TYPE: 'SET_TRANSACTION_TYPE',
  SET_QUANTITY: 'SET_QUANTITY',
  SET_PRODUCT: 'SET_PRODUCT', 
  SET_CURRENCY: 'SET_CURRENCY',
  SET_QUOTE: 'SET_QUOTE'
};

const actions = {
  async init({ dispatch, commit, rootState }) {
    await dispatch('currency/getCurrencies', null, { root: true })
    await dispatch('store/init', null, { root: true })
    await dispatch('cart/init', null, { root: true })
    await dispatch('product/loadProducts', null, { root: true })

    const cart = rootState.cart.shoppingCart

    const setTransactionTypeParams = (transactionType, productId) => ({ transactionType, productId: productId || null})

    // if there is an active cart we prefer its transactionType/product selection over the defaults.
    if (cart) {
      await dispatch('setTransactionType',setTransactionTypeParams(cart.product.transactionType, cart.product.id));
      return;
    }

    // TODO: #inappropriate-init - we should make it easier to initialize properties from the server side
    //  there are random fields all over the codebase that could be consolidated into a paramater passed to this action.
    //  e.g. currency, qty, transactionType, product, and affiliateCode.

    const docType = window.doc_type
    // TODO: window.isSellcurrency is badly named and weirdly a string, nuke it.
    const isBuyTransactionPage = docType === 'pageSellTravelMoney' || window.isSellcurrency === 'True'

    if (isBuyTransactionPage) {
      await dispatch('setTransactionType', setTransactionTypeParams(TransactionTypes.BUY, ProductTypes.SELL_BACK));
      return;
    }

    await dispatch('setTransactionType', setTransactionTypeParams(TransactionTypes.SELL, ProductTypes.CLICK_AND_COLLECT));
  },
  async getCalculatorContent({ commit }) {
    const { data } = await getContent()
    commit(SET_CALCULATOR_CONTENT, data)
  },
  getMatrix({ commit }) {
    getMatrix().then(({ data }) => {
      // TODO: Chucking this utility class instance into state is weird, refactor such that a getter is exposed.
      commit('setMatrix', new AvailabilityMatrix(data))
    })
  },
  [actionTypes.ToggleStoreModal]({ commit }, flag) {
    commit(SET_STORE_MODAL, flag)
  },
  [actionTypes.SetResult]({ commit }, flag) {
    commit(SET_RESULT, flag)
  },
  setTransactionType({ state, commit, dispatch }, { transactionType, productId }) {
    if (state.transactionType === transactionType) {
      console.warn('transactionType unchanged')
      return
    }

    commit(mutationTypes.SET_QUOTE, null)
    commit(mutationTypes.SET_TRANSACTION_TYPE, transactionType)

    const defaultProduct = transactionType === TransactionTypes.SELL
        ? ProductTypes.CLICK_AND_COLLECT
        : ProductTypes.SELL_BACK
    dispatch('selectProduct', productId || defaultProduct)
  },
  setQuantity({ state, commit }, quantity) {
    if (quantity == state.quantity) {
      console.warn('quantity unchanged')
      return
    }
    commit(mutationTypes.SET_QUANTITY, quantity)
  },
  selectProduct({ state, commit, dispatch, rootGetters }, productId) {
    if (state.selectedProduct?.id === productId) {
      console.warn('selectedProduct unchanged')
      return
    }

    const product = rootGetters['product/findProduct'](productId)
    commit(mutationTypes.SET_PRODUCT, product)
    dispatch('updateQuote', { productId: productId })
  },
  selectCurrency({ state, commit, dispatch }, currency) {
    if (state.selectedCurrency?.code === currency?.code) {
      console.warn('selectedCurrency unchanged')
      return
    }

    commit(mutationTypes.SET_CURRENCY, currency)
    dispatch('updateQuote', { currencyCode: currency.code })
  },

  /**
   * updateQuote
   * Gets the currency rate applicable for the given parameters from the API and sets it in the store
   * @param {Object} { commit, rootState }
   * @param selectedProduct
   * @param selectedCurrency
   * @param defaultStore
   *
   * @returns {Object} quote - set in the store
   */
  updateQuote({ commit, state, rootState, getters, rootGetters }, params) {
    const { store } = rootState

    const productId = params?.productId || state.quote?.productId || state.selectedProduct?.id
    const currencyCode =params?.currencyCode || state.quote?.currencyCode || state.selectedCurrency?.code
    const defaultBranchCode = store.defaultStore?.branchCode

    if (!productId || !currencyCode || !defaultBranchCode) {
      console.warn("Couldn't update quote", { productId, currencyCode, defaultBranchCode, })
      return
    }

    const transactionType = rootGetters['product/findProduct'](productId)?.transactionType
    const affiliateCode = sessionStorage.getItem('affiliateCode') || null

    const transactionTypeChanged = transactionType != state.quote?.transactionType;
    const currencyChanged = currencyCode != state.quote?.currencyCode;

    // Ignore "selected branch" (store.Id) if product selection is delivery as that can only be purchased
    // from the "home delivery branch" (isMeta = true).
    const branchId = productId === ProductTypes.HOME_DELIVERY
        ? store.defaultStore.id
        : store.store?.id || store.defaultStore.id

    // Commerce API call for currency rate
    getRate({
      storeId: branchId,
      productId: productId,
      currency: currencyCode,
      affiliateCode: affiliateCode,
    })
    .then(({ data }) => {
      const quote = {
        ...data,
        productId: productId,
        currencyCode: currencyCode,
        transactionType: transactionType, // NOTE: key to making increment warning stuff not a PITA.
      }

      commit(mutationTypes.SET_QUOTE, quote)

      if(transactionTypeChanged || currencyChanged) {
        switch (state.transactionType) {
          case TransactionTypes.BUY:{
            const qty = window.commerceSettings?.DEFAULT_FC || 250;
            commit(mutationTypes.SET_QUANTITY, qty);
            break;
          }
  
          case TransactionTypes.SELL: {
            const amount = window.calculatorSettings.amount || window.commerceSettings?.DEFAULT_GBP || 1000;
            if(window.calculatorSettings.native) {
              const qty = nextMultiple(quote.increment, amount);
              commit(mutationTypes.SET_QUANTITY, qty);
            }
            else {
              const qty = amount * getters.rateForSourceQty(amount,quote)
              commit(mutationTypes.SET_QUANTITY, qty);
            }
            break;
          }
  
          default:
            commit(mutationTypes.SET_QUANTITY, 1);
            break
        }
      }

      // TODO: Check rate tiers.
 

      /* NOTES:
        * ------------------------------------------------
        * As much as all the mental watchers have caused us a nightmare
        * that increment changing warning stuff would be handled really well by one.
        * The quote instance in state has transactionType, increment and currency code on it.
        * Watcher has oldstate and newState which can be used to work our whether to show message.
        * ------------------------------------------------
        */

      // if (data.increment != state.quote.increment) {
      //   const adjustedQuantity = nextMultiple(data.increment, state.quantity)
      //   const quantityChanged = adjustedQuantity != state.quantity

      //   if (quantityChanged) {
      //     commit(mutationTypes.SET_QUANTITY, adjustedQuantity)
      //   }

      //   const showRoundingWarning = quantityChanged && !currencyChanged && !transactionTypeChanged

      //   if (showRoundingWarning) {
      //     // The choice of location has affected the quantity so warn the user about this
      //     getContent().then((result) => {
      //       showWarning(
      //         `${result.data.incrementNotification} ${state.selectedCurrency.symbol}${data.increment}`
      //       )
      //     })
      //   }
      // }
    })
    .catch((err) => {
      if (err && err.response && err.response.data)
        showError(err.response.data.message)
    })
  },
}

const mutations = {
  [SET_CALCULATOR_CONTENT](state, content) {
    state.content = content
  },
  [SET_STORE_SELECT](state, flag) {
    state.showStore = flag
  },
  [SET_STORE_MODAL](state, flag) {
    state.showStoreModal = flag
  },
  setMatrix(state, availabilityMatrix) {
    state.matrix = availabilityMatrix
  },
  setCalculator(state, type) {
    state.type = type
  },
  [mutationTypes.SET_TRANSACTION_TYPE](state, transactionType) {
    state.transactionType = transactionType;
  },
  [mutationTypes.SET_QUANTITY](state, quantity) {
    state.quantity = quantity
  },
  [mutationTypes.SET_PRODUCT](state, product) {
    state.selectedProduct = product
  },
  [mutationTypes.SET_CURRENCY](state, currency) {
    state.selectedCurrency = currency
  },
  [mutationTypes.SET_QUOTE](state, quote) {
    state.quote = quote
  }
}

const getters = {
  isLoading: (state, getters, rootState) => {
    return state.content == null ||
      state.matrix == null ||
      rootState.store.defaultStore == null ||
      state.selectedCurrency == null
  },
  nextTier: (state, getters, rootState) => {
    if(!state.quote.rateTiers || state.quote.rateTiers.length < 1) {
      return null
    }

    const qty = state.quantity || 0;

    const sortedRates = (state.quote.rateTiers)
      .filter((i) => i.amount > qty)
      .sort((a, b) => a.amount - b.amount)

    return sortedRates[0] || null
  },
  tieredRate: (state, getters, rootState) => {
    const blankTieredRate = {
      amount: null,
      price: null,
      rate: null
    };

    if(!state.quote.rateTiers || state.quote.rateTiers.length < 1) {
      return blankTieredRate
    }

    const qty = state.quantity || 0;

    const sortedRates = state.quote.rateTiers
      .filter(tier => tier.amount <= qty)
      .sort((a, b) => b.amount - a.amount)

    return sortedRates[0] || blankTieredRate
  },
  rate: (state, getters, rootState) => {
    if (state.selectedCurrency === null || state.quote.rate === null)
      return 0

    return getters.tieredRate.rate
      ? getters.tieredRate.rate
      : state.quote.rate
  },
  // GBP -> FC
  rateForSourceQty: (state, getters) => (qty, quote) => {
    if(!quote) {
      quote = state.quote
    }

    const filteredThresholds = getters.rateTierThresholds(quote)
      .filter(threshold => qty >= threshold.threshold)

    const tiered = filteredThresholds[0] || null

    return  tiered?.rate || quote?.rate || 0;
  },
  // FC -> GBP
  rateForTargetQty: () => (qty, quote) => {
    if(!quote) {
      quote = state.quote
    }

    qty = nextMultiple(quote.increment, Math.floor(qty));

    const filteredRates = (quote.rateTiers || [])
      .filter((i) => i.amount <= qty)
      .sort((a, b) => b.amount - a.amount)

    const tiered = filteredRates[0] || null

    return tiered?.rate || quote?.rate || 0;
  },

  rateTierThresholds: () => (quote)  => {
    if(!quote.rateTiers || quote.rateTiers.length < 1)
      return []

    const thresholds = (quote.rateTiers)
      .map(x => {
        const threshold = BigNumber(x.amount).div(BigNumber(x.rate)).dp(2, BigNumber.ROUND_HALF_EVEN).toNumber()
        return {
          rate: x.rate,
          threshold
        }
      }); 

    return thresholds.sort((a, b) => b.threshold - a.threshold)
  },
  price: (state, getters, rootState) => {
    if (state.quote.rate === null)
      return 0

    return getters.tieredRate.price
      ? getters.tieredRate.price
      : state.quote.price
  },
  // selling to customer
  isSellTransaction: (state) => state.transactionType === TransactionTypes.SELL,
  // buying from customer
  isBuyTransaction: (state) => state.transactionType === TransactionTypes.BUY,
}

const calculator = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default calculator;
