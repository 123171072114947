import BigNumber from 'bignumber.js' // .js isn't extension, it's part of module name

export const sourceAmountToTargetQty = (amount, rate) => {
  return BigNumber(amount)
    .times(BigNumber(rate))
    .dp(2, BigNumber.ROUND_CEIL)
    .toNumber()
    .toFixed(2)
}

export const targetAmountToSourceQty = (amount, rate) => {
  return BigNumber(amount)
    .dividedBy(BigNumber(rate))
    .dp(2, BigNumber.ROUND_CEIL)
    .toNumber()
    .toFixed(2)
}

export const calculateTotalPrice = (source, price) => {
  if (!price || source < 1) return null

  return BigNumber(source)
    .times(BigNumber(price))
    .dp(2, BigNumber.ROUND_CEIL)
    .toNumber()
}

export const nextMultiple = (denom, quantity) => {
  if (quantity === 0) return denom;
  if (quantity % denom === 0) return quantity;
  quantity = Math.floor(quantity / denom);
  return BigNumber(quantity).times(BigNumber(denom)).toNumber() + denom;
}

export const isNumber = (evt) => {
  evt = evt ? evt : window.event
  const charCode = evt.which ? evt.which : evt.keyCode
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false
  }
  return true
}

export const convertSourceToTarget = (quantity, rate) => {
  if (!quantity) {
    return
  }

  return convert(true, quantity, rate)
}

export const convertTargetToSource = (quantity, rate) => {
  if (!quantity) {
    return
  }

  return convert(false, quantity, rate)
}

export const convert = (isTarget, value, rate) => {
  const quantity = parseFloat(value)
  let source
  let target

  if (isNaN(quantity)) {
    source = window.calculatorSettings.amount;
    target = sourceAmountToTargetQty(window.calculatorSettings.amount, rate);

    return;
  }

  if (isTarget) {
    source = quantity
    target = sourceAmountToTargetQty(quantity, rate)
  } else {
    target = quantity
    source = targetAmountToSourceQty(quantity, rate)

    if (source > 2500) {
      target = sourceAmountToTargetQty(2500, rate)
      source = 2500
    }
  }

  return {
    source,
    target,
  }
}

export default {
  sourceAmountToTargetQty,
  targetAmountToSourceQty,
  calculateTotalPrice,
  convertSourceToTarget,
  convertTargetToSource,
  convert,
}
