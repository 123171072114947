<template>
  <div>

    <div class="row address-search" >
      <label for="customer-address-form-autocomplete">
        <span>Start typing your address to search</span>
      </label>
      <input type="text" id="customer-address-form-autocomplete" tabindex="0" placeholder="Address search"
        ref="autocomplete" />
    </div>

    <div class="row" :class="{ validator: validator.houseNameNumber.$error }">
      <label for="customer-address-form-house-name-or-number">
        <span>House name or number *</span>
      </label>
      <input type="text" name="houseNameNumber" id="customer-address-form-house-name-or-number" tabindex="0"
        v-model="address.houseNameNumber" @input="addressChanged" placeholder="House name or number" />
      <span class="error" v-if="!validator.houseNameNumber.required">
        Please enter your house name or number
      </span>
      <span class="error" v-if="!validator.houseNameNumber.maxLength">
        You've used the maximum number of characters available (250)
      </span>
    </div>

    <div class="row" :class="{ validator: validator.addressLine1.$error }">
      <label for="customer-address-form-address-line-1">
        <span>Address line 1 *</span>
      </label>
      <input type="text" name="addressLine1" id="customer-address-form-address-line-1" tabindex="0"
        v-model="address.addressLine1" @input="addressChanged" placeholder="Address line 1" />
      <span class="error" v-if="!validator.addressLine1.required">
        Please fill in the first line of your address
      </span>
      <span class="error" v-if="!validator.addressLine1.maxLength">
        You've used the maximum number of characters available (250)
      </span>
    </div>

    <div class="row">
      <label for="customer-address-form-address-line-2">
        <span>Address line 2</span>
      </label>
      <input type="text" name="addressLine2" id="customer-address-form-address-line-2" tabindex="0"
        v-model="address.addressLine2" @input="addressChanged" placeholder="Address line 2" />
    </div>

    <div class="row">
      <div class="inputs">
        <div class="input" :class="{ validator: validator.townCity.$error }">
          <label for="customer-address-form-town-city">
            <span>Town / City *</span>
          </label>
          <input type="text" name="townCity" id="customer-address-form-town-city" tabindex="0" v-model="address.townCity"
            @input="addressChanged" placeholder="Town / City" />
          <span class="error" v-if="!validator.townCity.required">
            Please enter your town, city or village
          </span>
          <span class="error" v-if="!validator.townCity.maxLength">
            You've used the maximum number of characters available (250)
          </span>
        </div>

        <div class="input" :class="{ validator: validator.postcodeZip.$error }">
          <label for="customer-address-form-postcode">
            <span>Postcode *</span>
          </label>
          <input type="text" name="postcodeZip" id="customer-address-form-postcode" tabindex="0"
            v-model="address.postcodeZip" @input="addressChanged" placeholder="Postcode"/>
          <span class="error" v-if="!validator.postcodeZip.required">
            Please enter your post code
          </span>
          <span class="error" v-if="!validator.postcodeZip.isPostcode">
            Please enter a valid post code
          </span>
          <span class="error" v-if="!validator.postcodeZip.maxLength">
            You've used the maximum number of characters available (250)
          </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { mapGooglePlaceToCommerceAddress } from '@vue/common/addressMapper/mapGooglePlaceToCommerceAddress';

const getAddressComponent = (place, type) => {

  let component = place.address_components.filter(x => x.types.includes(type))[0]

  if (!component) {
    return null;
  }

  return component.long_name || null;
};

export default {
  name: 'CustomerAddressFormPartial',
  props: {
    validator: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      address: { ...this.value }
    }
  },
  methods: {
    addressChanged() {
      this.$emit('input', this.address);
    },
    updateAddressFromPlace(place) {
      if (!place.address_components) {
        this.reset();
        return;
      }

      this.address = mapGooglePlaceToCommerceAddress(place);
      this.addressChanged();
    },
    reset() {
      this.address.houseNameNumber = '';
      this.address.addressLine1 = '';
      this.address.addressLine2 = '';
      this.address.townCity = '';
      this.address.postcodeZip = '';
      this.addressChanged();
    }
  },
  mounted() {
    const autocompleteOptions = {
      componentRestrictions: { country: 'gb' },
      fields: ['address_components'],
      types: ['geocode']
    };

    const autocomplete = new google.maps.places.Autocomplete(this.$refs.autocomplete, autocompleteOptions);
    autocomplete.addListener('place_changed', () => this.updateAddressFromPlace(autocomplete.getPlace()));
  }
}
</script>
