var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "store",
        class: "store--brand__" + _vm.store.brand.toLowerCase(),
      },
      [
        _c("div", { staticClass: "store--inner" }, [
          _c("h3", [_vm._v(_vm._s(_vm.store.name))]),
          _vm._v(" "),
          _vm.content[
            "branchTypeAdvisory" + _vm.capitalCase(_vm.store.branchType)
          ]
            ? _c("p", { class: "store--brand__" + _vm.store.branchType }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.content[
                        "branchTypeAdvisory" +
                          _vm.capitalCase(_vm.store.branchType)
                      ]
                    ) +
                    "\n      "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("p", [
            _vm._v("Distance: " + _vm._s(_vm.store.distanceInMiles) + " miles"),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    !_vm.IsPayPoint
      ? _c("div", [
          _vm.availableFrom !== null
            ? _c("div", [
                _vm.store.haveCurrency && _vm.isSellTransaction
                  ? _c("div", { staticClass: "store--stock available" }, [
                      _c("p", [
                        _vm._v(
                          "In Stock - Collect from " + _vm._s(_vm.availableFrom)
                        ),
                      ]),
                    ])
                  : _c("div", [
                      _c("p", [
                        _vm._v(
                          "Collect in branch " + _vm._s(_vm.availableFrom)
                        ),
                      ]),
                    ]),
              ])
            : _c("div", [_vm._m(0)]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.expanded
      ? _c(
          "div",
          { staticClass: "store--info" },
          [
            _c("times", { attrs: { storeId: _vm.store.id } }),
            _vm._v(" "),
            _c("div", { staticClass: "store--address" }, [
              _c("b", [_vm._v("Collection address")]),
              _vm._v(" "),
              _c("div", { staticClass: "address" }, [
                _vm.store.brandName || (_vm.store.brand && _vm.store.name)
                  ? _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.store.brandName || _vm.store.brand) +
                          "\n          " +
                          _vm._s(_vm.store.name) +
                          ",\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.address != null
                  ? _c("span", [
                      _vm.address.address1
                        ? _c("span", [
                            _vm._v(" " + _vm._s(_vm.address.address1) + ", "),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.address.address2
                        ? _c("span", [
                            _vm._v(" " + _vm._s(_vm.address.address2) + ", "),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.address.address3
                        ? _c("span", [
                            _vm._v(" " + _vm._s(_vm.address.address3) + ", "),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.address.address4
                        ? _c("span", [
                            _vm._v(" " + _vm._s(_vm.address.address4) + ", "),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.address.postcode
                        ? _c("span", [_vm._v(_vm._s(_vm.address.postcode))])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "brand", class: _vm.store.brand }),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.IsPayPoint && _vm.availableFrom !== null
      ? _c(
          "span",
          { staticClass: "button button--small button--show-stores pp-stores" },
          [
            _vm.availableFrom == "today"
              ? _c("div", [
                  _c("p", { staticClass: "pp-stor" }, [
                    _vm._v("In Stock - "),
                    _c("span", { staticClass: "pp-collect" }, [
                      _vm._v(
                        " Collect from " + _vm._s(_vm.availableFrom) + " "
                      ),
                    ]),
                  ]),
                ])
              : _vm.availableFrom == "tomorrow" ||
                _vm.availableFrom == "2pm tomorrow"
              ? _c("div", [
                  _c("p", { staticClass: "pp-stor" }, [
                    _c("span", { staticClass: "pp-collect" }, [
                      _vm._v(
                        " Collect from " + _vm._s(_vm.availableFrom) + " "
                      ),
                    ]),
                  ]),
                ])
              : _c("div", [
                  _c("p", { staticClass: "pp-stor" }, [
                    _c("span", { staticClass: "pp-collect" }, [
                      _vm._v(" Collect " + _vm._s(_vm.availableFrom) + " "),
                    ]),
                  ]),
                ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.store.haveNoOpeningTimes
      ? _c(
          "button",
          {
            staticClass: "button button--small button--show-stores",
            class: {
              active: _vm.expanded,
              "pp-popup pp-showInfo": _vm.IsPayPoint,
            },
            on: {
              click: function ($event) {
                return _vm.toggleExpanded()
              },
            },
          },
          [
            _vm._v(
              "\n\n    " +
                _vm._s(
                  _vm.expanded
                    ? _vm.content.hideBranchInfo || "Hide branch information ▲"
                    : _vm.content.showBranchInfo || "Show branch information ▼"
                ) +
                "\n  "
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.store.haveNoOpeningTimes
      ? _c(
          "button",
          {
            staticClass: "button button--small button--primary",
            class: { "pp-confirm": _vm.IsPayPoint },
            on: { click: _vm.confirmStoreSelection },
          },
          [_vm._v("\n    Confirm\n  ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "store--stock closed" }, [
      _c("p", [_vm._v("Sorry we're closed this week!")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }