<template>
  <div class="calculator-select">
    <div class="options tabs" tabindex="-1">
      <button
        v-for="option in options"
        :key="option.id"
        :class="{ active: transactionType === option.id }"
        @click.prevent="selectTransactionType(option.id)"
        class="tab"
      >
        {{ option.label }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { TransactionTypes } from '@vue/common/constants/transactionTypes';

export default {
  name: 'CalculatorSelect',
  data() {
    return {
      options: [
        {
          label: 'Buy Travel Money', // customer perspective
          id: TransactionTypes.SELL, // our perspective
        },
        {
          label: 'Sell Travel Money',  // customer perspective
          id: TransactionTypes.BUY,    // our perspective
        },
      ],
    }
  },
  computed: {
    ...mapState({
      selectedProduct: (state) => state.calculator.selectedProduct,
      transactionType: (state) => state.calculator.transactionType,
    }),
  },
  methods: {
    ...mapActions({
      abandonCart: 'cart/abandonCart',
      setTransactionType: 'calculator/setTransactionType',
    }),
    selectTransactionType(transactionType) {
      this.abandonCart(); // TODO: Do we really want this behaviour, why not leave cart alone till customer asks to nuke it.
      this.setTransactionType({transactionType});
    }
  },
}
</script>

<style scoped>
.tab {
  border-radius: 0;
  border: none;
  padding: 14px 25px;
  margin: 0;
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  line-height: 16.25px;
  border-color: var(--color-primary);
  background: var(--color-primary);
  color: #fff;
}

.tab.active {
  border: 1px solid #fff;
  background-color: #fff;
  color: var(--color-primary);
  outline: none;
}

a:hover {
  color: var(--color-primary);
  background: #fff;
}
</style>
