<template>
  <div
    class="amount-select"
    v-if="selectedCurrency && selectedProduct"
    :class="{ 'visibility-hidden': !quote }">
    <div class="label">
      <div class="selling row">
        <span>{{
          content.amountSelectSellLabel || '2. Amount to Sell Back'
        }}</span>
        <div class="row">
          <span>You will receive</span>
          <Tooltip :content="content.sellingTooltip" />
        </div>
      </div>
      <div class="row"></div>
    </div>
    <div class="inputs">
      <div class="input" tabindex="-1">
        <small class="currency-code" v-html="selectedCurrency.symbol"></small>
        <input
          type="number"
          min="0"
          @keyup="onUpdateSource"
          :value="desiredSourceQuantity"
          tabindex="0"
        />
      </div>
        <div class="row">
          <div class="icon icon__gbp"></div>
          <span class="currency-code">£</span>
          <span class="sell">{{ desiredTargetQuantity.toFixed(2) }}</span>
        </div>
    </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js'
import roundingUtils from '@vue/utils/rounding.utils'
import { mapState, mapActions } from 'vuex'

import Tooltip from '@vue/common/components/Tooltip'

export default {
  name: 'AmountSelectSell',
  components: {
    Tooltip,
  },
  data() {
    return {
      debounceTimer: null,
      desiredSourceQuantity: null,
      desiredTargetQuantity: null,
    }
  },
  watch: {
    'quote': {
      handler(newQuote, oldQuote) {
        if(newQuote?.currencyCode == oldQuote?.currencyCode && 
           newQuote?.increment == oldQuote?.increment &&
           newQuote?.rate == oldQuote?.rate) {
          return;
        }

        // NOTE: This code handles on mount + on quote changed, user input is in methods below.
        // We round here to ensure we dont show > 2dp when presenting state (in state it is reasonable to be 5dp +)
        const qty = this.$store.state.calculator.quantity
        this.desiredSourceQuantity = roundingUtils.roundHalfEven(qty, 2);
        this.desiredTargetQuantity = roundingUtils.roundHalfEven(BigNumber(qty).div(newQuote.rate), 2);;
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      content: (state) => state.calculator.content,
      quote: (state) => state.calculator.quote,
      selectedCurrency: (state) => state.calculator.selectedCurrency,
      selectedProduct: (s) => s.calculator.selectedProduct,
    }),
  },
  methods: {
    ...mapActions({
      setQuantity: 'calculator/setQuantity',
    }),
    onUpdateSource(event){
      if(event.key == 'Tab') {
        return;
      }

      this.resetTimer();

      this.debounceTimer = setTimeout(() => {
        this.resetTimer();
        this.updateSource(Number(event.target.value) || 0);
      }, 500)
    },
    canUpdate() {
      // This component shouldn't ever receive rates for other products, but right now it does so handle it until refactor.
      return this.quote.rate && this.selectedProduct?.id == 3;
    },
    updateSource(quantity) {
      if(!this.canUpdate()){
        this.reset();
        return;
      }

      const sourceQuantity = roundingUtils.roundHalfEven(quantity, 2);
      const targetQuantity = roundingUtils.roundHalfEven(BigNumber(sourceQuantity).div(this.quote.rate), 2);

      this.desiredSourceQuantity = sourceQuantity;
      this.desiredTargetQuantity = targetQuantity;

      this.setQuantity(Math.floor(sourceQuantity))
    },
    reset() {
      this.desiredSourceQuantity = 0;
      this.desiredTargetQuantity = 0;

      this.setQuantity(0);
    },
    resetTimer(){
      clearTimeout(this.debounceTimer);
      this.debounceTimer = null;
    }
  },
}
</script>
