import BigNumber from 'bignumber.js'

export default {

  /*
  * For general rounding operations (depends on BigNumber library)
  *
  * @example
  *
  * import utils from '@vue/utils/rounding.utils'
  * utils.roundHalfEven(2).then(() => {
  *   // 
  * })
  */

  roundHalfEven: (n, dp) => {
    return BigNumber(n).dp(dp, BigNumber.ROUND_HALF_EVEN).toNumber()
  }
}
