import { getField, updateField } from 'vuex-map-fields'
import { showError } from '@vue/services/toast.service'

import {
  getDefaultStore,
  getStores,
  getStoreById,
  getNearby,
  getStoreByCode,
} from '@vue/services/api/store.api'

import { SET_STORES } from '@vue/common/constants/mutation.types'

import { actionTypes } from '@vue/common/constants/store.types'

const initialState = {
  stores: [],
  defaultStore: null,
  store: null
}

const state = { ...initialState }

const actions = {
  async init({ dispatch, commit, rootState }) {
    const defaultBranch = await getDefaultStore().then((r) => r.data)
    const branchCode = window.calculatorSettings.branchCode
    const selectedBranch = branchCode
      ? await getStoreByCode(branchCode).then((r) => r.data)
      : null

    commit('setDefaultStore', defaultBranch)
    commit('setStore', selectedBranch || defaultBranch)
  },
  getStores({ commit }) {
    getStores().then(({ data }) => {
      commit(
        SET_STORES,
        data.filter(store => store.isMeta)
      )
    })
  },
  getStore({ commit }, id) {
    getStoreById(id).then(({ data }) => {
      commit('setStore', data)
    })
  },
  removeStoreSelection({ commit }) {
    commit('setStore', null)
  },
  [actionTypes.GetNearby]({ commit }, e) {
    getNearby({
      latitude: e.Latitude,
      longitude: e.Longitude,
      amount: e.amount,
      currency: e.currency,
      transactionType: e.transactionType
    }).then(({ data }) => {
      commit(SET_STORES, data)
    }).catch((err) => {
      if (err && err.response && err.response.data)
        showError(err.response.data.message);
    });
  },
  clearStores({ commit }) {
    commit('clearStores')
  },
}

const mutations = {
  updateField,
  setDefaultStore(state, defaultStore) {
    state.defaultStore = defaultStore
  },
  setStore(state, store) {
    state.store = store
  },
  [SET_STORES](state, stores) {
    state.stores = stores
  },
  clearStores(state) {
    state.stores = []
  }
}

const getters = {
  getField,
  loading: state => !state.stores.length
}

const store = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default store
